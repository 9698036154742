import {
  Content,
  Page,
  PageTitle,
} from '@sh24/ui-components'
import documentToReactComponents from '../../utils/document-to-react-components'
import BackNavigation from '../../components/BackNavigation/back-navigation'
import { SearchInputModule } from '../../components/Modules'

const titleIconMap = {
  completion: 'tick-2',
}

const StandardHeader = ({ page, config, backPath }) => {
  const titleIcon = titleIconMap[page.pageType] || null

  const header = [
    page.title ? <PageTitle key={page.title} text={page.title} icon={titleIcon} size={page.titleSize} /> : null,
    (page.headerContent ? (
      <div key="header-content" className="heading-3 mt-base">
        {documentToReactComponents(page.headerContent)}
      </div>
    ) : null),
  ]

  return (
    <Page.Section
      backgroundColour={config.backgroundColours.first}
      decoration={config.decorations.first}
    >
      <Page.Content>
        <BackNavigation page={page} width={config.textWidth} backPath={backPath} />

        <Content width={config.textWidth} textAlign={config.textAlign} content={header} />
        {page.headerSearchInput && (<SearchInputModule {...page.headerSearchInput} />)}
      </Page.Content>
    </Page.Section>
  )
}

export default StandardHeader
