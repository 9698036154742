export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/primary',
    middle: [
      '/white',
      '/tertiary300',
    ],
    last: '/primary',
  },
  backgroundOverrides: {
    helpSnippets: '/white',
    quote_grid: '/primary400',
  },
  decorations: {
    first: 'None',
    middle: [
      '/curve/quarter/leftInverse',
      '/curve/quarter/left',
      '/curve/semiInverse',
      '/curve/semi',
    ],
  },
  modules: {
    content: {
      width: 'standard',
    },
  },
}
