export default {
  textAlign: 'left',
  textWidth: 'standard',
  backgroundColours: {
    first: '/primary200',
    middle: [
      '/white',
      '/tertiary200',
      '/primary200',
    ],
    last: '/white',
  },
  backgroundOverrides: {
    helpSnippets: '/white',
    quote_grid: '/primary200',
  },
  decorations: {
    first: '/curve/quarter/left',
    middle: [
      '/curve/quarter/right',
      '/curve/quarter/rightInverse',
      '/curve/quarter/right',
      '/curve/semiInverse',
    ],
  },
  modules: {
    content: {
      width: 'standard',
    },
  },
}
